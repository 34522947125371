import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const CrestPlaceholderImg = () => {
    const imgData = useStaticQuery(graphql`
    {
    crestPlaceholder: file(relativePath: { eq: "USS-IDAHO-Crest.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <Img fluid={imgData.crestPlaceholder.childImageSharp.fluid} alt="USS IDAHO Crest" />
  )
  }

  export default CrestPlaceholderImg