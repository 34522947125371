import React from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import SelectIcon from '../images/SelectIcon'
//import { Button } from 'react-bootstrap'

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 1.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
`

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 2;
`
const FlexBox = styled.div`
    display: flex; 
    flex-flow: row wrap;
    justify-content: space-around;
`

const Numbers = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  display: inline-block;
  float: left;
  color: #df4f19;
  padding: 1rem;
  background: white;
  position: relative;
  transition: 0.3s all;
  svg {
    fill: #df4f19;
    margin: 0 0 0 0.25rem;
    transition: 0.3s all;
  }
  &:hover {
    background: darkgrey;
  }
  @media (hover: none) {
    background: white !important;
  }
`

const Select = styled.select`
-webkit-appearance: none;
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: transparent;
  option {
    color: #df4f19;
  }
`


const Pagination = props => {
  function changePage(e) {
    navigate(
      e.target.value
        ? `${props.context.paginationPrefix}/${e.target.value}`
        : `${props.context.paginationPrefix}/`
    )
  }

  return (
      <>
      {props.context.numberOfPages > 1 && (
        <Wrapper>
          <Numbers>
            {props.context.humanPageNumber}{' '}
            <Select
              value={
                props.context.humanPageNumber === 1
                  ? ``
                  : props.context.humanPageNumber
              }
              onChange={changePage}
            >
              {Array.from({ length: props.context.numberOfPages }, (_, i) => (
                <option value={`${i === 0 ? `` : i + 1}`} key={`page${i + 1}`}>
                  {i + 1}
                </option>
              ))}
            </Select>
            / {props.context.numberOfPages} <SelectIcon />
          </Numbers>
          <div>
            {props.context.previousPagePath && (
              <PreviousLink to={`${props.context.previousPagePath}`}>
                <span>&larr;</span> Previous
              </PreviousLink>
            )}
            {props.context.nextPagePath && (
              <NextLink style={{ order: 3 }} to={`${props.context.nextPagePath}`}>
                Next <span>&rarr;</span>
              </NextLink>
            )}
          </div>
        </Wrapper>
      )}
    </>
    )
}

export default Pagination