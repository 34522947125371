import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
        faAngleRight
    } from "@fortawesome/pro-solid-svg-icons"

import CrestPlaceholderImg from '../components/NewsBlock/CrestPlaceHolderImg'

export default ({ article }) => (
  <div className="newsItems mb-4">
  <Row>
      {!article.image ? <Col sm={3}><CrestPlaceholderImg /></Col> : (
              <Col md={3}>
              <Link to={`/news/${article.slug}`}>
              <img style={{
                  width: '100%'
                }}
              src={article.image ? article.image.fluid.src : null } alt={article.title} className="rounded" />
              </Link>
        
          </Col> ) }
        <Col md={9}>
        <p className="post-title"><Link to={`/news/${article.slug}`}>{article.title}</Link><br />
          <span className="updated">{article.updatedAt}</span></p>
          <p>{article.excerpt.postExcerpt}</p>
          <div><Link to={`/news/${article.slug}`}>Read More <span>
          <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                        fontSize: '1rem',
                        marginRight: '5px',
                    }}
                    aria-hidden="true"
                    
                />
            </span></Link></div>
    
        </Col>
      </Row>
      <hr />
      </div>
)