import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import ArticlePreview from "../components/articlePreview"
import Pagination from "../components/Pagination"

const News = ({ data, pageContext }) => {

  const posts = data.allContentfulNewsPost.edges
  // const { humanPageNumber } = pageContext
  //const isFirstpage = humanPageNumber === 1
  return (

    <Layout>
    <SEO title="USS IDAHO SSN 799 News"/>
    <Container fluid className="container-fluid-full">
      <PageTitle pageTitle="USS IDAHO SSN 799 News" >
        {/* {page.pageTitle} */}
      </PageTitle>
      <ContentBlock>
        <div style={{
           display: 'flex',
           flexFlow: 'row wrap'
         }}>
     { posts.map(({ node }) => {
       return (
         
           <ArticlePreview article={node} />
           
       )
     }) }
      </div>
<Pagination context={pageContext} />
</ContentBlock>
</Container>
  </Layout>

  
  )
}

export const getBlogPost = graphql`
query ($skip: Int, $limit: Int) {
    allContentfulNewsPost (
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
      ) {
      totalCount
      edges {
        node {
          slug
          title
          createdAt(formatString: "dddd MMMM Do, YYYY")
          excerpt: childContentfulNewsPostPostExcerptTextNode {
            postExcerpt 
          }
          image: featuredImage {
            fluid(maxWidth: 600) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

export default News
